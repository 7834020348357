import React, { useState } from "react";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import { useStaticQuery, graphql } from "gatsby";
import Navigation from "../components/navigation/navigation";
import Seo from "../components/seo/seo";
import Contact from "../components/contact/contact";
import HeaderBcg from "../assets/images/404/404.png";

const NotFoundPage = () => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);

  return (
    <section className="m-404">
      <Navigation openModal={openModal} setOpenModal={setOpenModal} />
      {openModal && <Contact setOpenModal={setOpenModal} />}{" "}
      <Seo title="Page not found" />
      <div className="_wr">
        <div className="m-404__content">
          <div
            className="m-404__image"
            style={{ backgroundImage: `url("${HeaderBcg}")` }}
          >
            {" "}
          </div>
          <h3>{t("notFound")}</h3>
          <p className="m-404__description"> {t("404Text")}</p>
          <Link to="/" className="a-btn -primary">
            Go back
          </Link>
        </div>
      </div>
    </section>
  );
};

export default NotFoundPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
